import {delayedInit} from "@/laraberg_frontend/general"

delayedInit(initCategoryDetails, 300)

async function initCategoryDetails() {
    const categoryDetails = document.getElementById("category-details")

    if (categoryDetails) {
        const [{createApp}, AddInterest] = await Promise.all([
            import("vue"),
            import("@/components/Buttons/AddInterest.vue")
        ])

        const CategoryDetailsApp = createApp({})

        CategoryDetailsApp.component("add-interest", AddInterest.default)
        CategoryDetailsApp.mount("#category-details")
    }
}
